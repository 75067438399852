<template>
    <div>
        <el-dialog
            :visible="dialogVisible"
            width="890px"
            :show-close="false"
            v-loading="loading"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            custom-class="job-bind-dept"
            class="job-bind-dept-wrapper"
            :append-to-body="true"
        >
            <span slot="title">
                职位绑定部门
            </span>
            <div class="job-bind">
                <div class="job-bind-title">
                    你有{{total}}个职位未绑定部门，请及时绑定。
                </div>
                <div class="job-bind-tip">
                    请及时绑定以下职位的部门，已处理<span class="text-primary">{{doneNum}}/{{total}}</span>。
                </div>
                <el-table
                    style="width:100%"
                    height="360px"
                    ref="jobBindDeptTable"
                    :data="jobList"
                    v-loading="jobTableLoading"
                    @selection-change="handleSelectionChange"
                    v-if="doneNum < total">
                    <el-table-column
                        type="selection"
                        width="28"
                    ></el-table-column>
                    <el-table-column
                        prop="jobName"
                        label="职位名称"
                        width="170"
                        :resizable="false"
                        :show-overflow-tooltip="true"
                    >
                        <template slot="header">
                            <div class="table-slot">
                                职位名称
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <el-tooltip
                                effect="light"
                                :content="scope.row.jobName"
                                placement="top"
                                :open-delay="300"
                                :disabled="tooltipDisabled"
                                @mouseenter.native ="handleShowTooltip($event)"
                            >
                                <span
                                    class="job-link"
                                    @click.prevent="handleJumpDetail(scope.row, 'job')"
                                >
                                    {{scope.row.jobName}}
                                </span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="客户名称"
                        prop="customerName"
                        width="116"
                        header-align="left"
                        :show-overflow-tooltip="true"
                    >
                        <template slot="header">
                            <select-popover
                                ref="selectPopover"
                                :enableSearch="true"
                                :enableCount="true"
                                :selectItem="customerNameSearchGroupedItems"
                                :label="'customerNames'"
                                :title="'客户名称'"
                                :maxSelect="5"
                                @selectChange="selectDataChange"
                            ></select-popover>
                        </template>
                        <template slot-scope="scope">
                            <el-tooltip
                                effect="light"
                                :content="scope.row.customerName"
                                placement="top"
                                :open-delay="300"
                                :disabled="tooltipDisabled"
                                @mouseenter.native ="handleShowTooltip($event)"
                            >
                                <!-- <span
                                    class="customer-detail-link"
                                    @click.prevent="handleJumpDetail(scope.row, 'customer')"
                                >
                                    {{scope.row.customerName}}
                                </span> -->
                                <span>{{scope.row.customerName}}</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="240"
                    >
                        <template slot="header">
                            <div class="table-slot">
                                操作（请处理）
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <bubble
                                v-for="(item, index) in operations"
                                :key="index"
                                :enableCount="false"
                                :enableTooltip="item.enableTooltip && !scope.row.jobStatus"
                                :isSelect="item.status == scope.row.jobStatus"
                                group="Label"
                                :type="item.color"
                                @click="handleJobStatus(item, scope.$index)"
                            >
                                {{ item.text }}
                                <span v-if="item.enableTooltip && !scope.row.jobStatus" slot="tooltipContent">
                                    {{ item.toolTip}}
                                </span>
                            </bubble>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="140"
                        prop="created"
                    >
                        <template slot="header">
                            <div class="table-slot time-sort" @click="sortTime(0)">
                                发布时间
                                <i class="el-icon-sort sort-icon"></i>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <span>
                                {{ scope.row.releaseTime | formatDate }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="140"
                        prop="updated"
                    >
                        <template slot="header">
                            <div class="table-slot time-sort" @click="sortTime(1)">
                                最近更新时间
                                <i class="el-icon-sort sort-icon"></i>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <span>
                                {{ scope.row.updated | formatDate }}
                            </span>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <div>
                            <span class="empty-img"></span>
                            <p class="empty-data">暂无数据</p>
                        </div>
                    </template>
                </el-table>
                <div class="job-bind-done" v-if="jobData.length == 0">
                    <p>真棒，所有职位已经处理完毕。</p>
                    <p>更新其他招聘中职位，请<a href="/Headhunting/#/myOrders/createdJob?status=1" target="_blank" class="text-operate-btn">点击这里</a></p>
                </div>
                <div class="work-table-footer">
                    <div class="footer-left">
                        <div class="footer-select">
                            <input hidden v-model="allSelect" type="checkbox">
                            <span class="all-select-checkbox" @click="handleAllSelect"></span>
                            <span>全选</span>
                            <span class="select-count">已选择
                                <span class="color-orange">{{multipleCount}}</span>
                                个职位
                            </span>
                        </div>
                        <div class="batch-operation">
                            <el-button
                                class="batch-operation-btn job-recruiting"
                                :disabled="!canBatchOperate"
                                @click="multipleJobBind"
                            >
                                绑定部门
                            </el-button>
                            <!-- <el-popover
                                placement="bottom-start"
                                popper-class="popper-bubble"
                                width="auto"
                                :disabled="multipleCount == 0"
                                :visible-arrow="false"
                                trigger="hover"
                            >
                                <el-button
                                    slot="reference"
                                    class="batch-operation-btn job-recruiting"
                                    :disabled="multipleCount == 0"
                                    @click="multipleJobStatus(1, 'batchRecruitingJob', '仍在招聘')"
                                >
                                    仍在招聘
                                </el-button>
                                <span class="bubble-tooltip">
                                    选中后，将更新该职位的更新时间
                                </span>
                            </el-popover>
                            <el-button
                                class="batch-operation-btn job-paused"
                                :disabled="multipleCount == 0"
                                @click="multipleJobStatus(2, 'batchPausedJob', '职位暂停')"
                            >
                                职位暂停
                            </el-button>
                            <el-button
                                class="batch-operation-btn job-finished"
                                :disabled="multipleCount == 0"
                                @click="multipleJobStatus(4, 'batchFinishedJob', '职位完成')"
                            >
                                职位完成
                            </el-button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog-close" @click="hideDialog">
                <span class="close-tip">
                    <template v-if="isCfUser && jobData.length !== 0">
                        请及时处理，否则将不能创建新职位。<br/>
                    </template>
                    关闭后，可在页面右侧再次打开。
                </span>
            </div>
        </el-dialog>
        <bind-department-dialog
            ref="bindDepartment"
            :isBatch="true"
            :jobList="bindJobList"
            :jobInfo="bindJobInfo"
            :customerId="bindCustomerId"
            @bind-department-confirm="doBind"
            append-to-body
        ></bind-department-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import Bubble from '#/component/common/bubble.vue';
import SelectPopover from '#/component/common/select-popover.vue';
import BindDepartmentDialog from '#/component/common/dialog/bind-department-dialog.vue';
import JobService from '#/js/service/jobService.js';
import myOrdersService from "@src/js/service/myOrdersService.js";
export default {
    components: {
        Bubble,
        SelectPopover,
        BindDepartmentDialog
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            jobList: [],
            jobTableLoading: false,
            multipleSelection: [],
            customerNameSearchGroupedItems: [],
            pager: {
                current: 1,
                size: 8,
                total: 0
            },
            tooltipDisabled: true,
            operations: [{
                enableTooltip: false,
                status: 1,
                color: 'primary',
                text: '绑定部门',
                type: "recruitingJob",
                toolTip: ''
            }],
            createdNewToOld: false,
            updatedNewToOld: true, 
            jobData: [],
            jobFilterList: [],
            customerFilter: [],
            dialogId: '',
            bindCustomerId: '',
            bindJobList: [],
            bindJobInfo: {},
            total: 0,
            doneNum: 0
        }
    },
    computed: {
        isCfUser() {
            return this.$store.state.user.userInfo.isCFUser ? this.$store.state.user.userInfo.isCFUser : $('input[name="isCFUser"]').val();
        },
        multipleCount() {
            return this.multipleSelection.length;
        },
        allSelect() {
            return this.jobList.length > 0 ? this.jobList.length == this.multipleSelection.length : false;
        },
        canBatchOperate() {
            let result = false;
            if(this.multipleSelection.length > 0) {
                let arr = [];
                this.multipleSelection.forEach(item => arr.push(item.customerId));
                if(new Set(arr).size == 1) {
                    result = true;
                }
            }
            return result;
        }
    },
    filters: {
        formatDate(string) {
            return moment(string).format('YYYY.MM.DD');
        }
    },
    methods: {
        show(dialogId) {
            this.dialogVisible = true;
            this.dialogId = dialogId;
            this.getJobData();
        },
        getJobData() {
            this.loading = true;
            this.jobData = [];
            JobService.getUnbindDeptJobList({
                customerId: '',
                orderBy: 0
            }).then(res => {
                let jobIds = [];
                res.data.forEach(item => {
                    jobIds.push(item.jobId);
                });
                // res.data.sort((item1, item2) => {
                //     return new Date(item2.updated).getTime() - new Date(item1.updated).getTime()
                // })
                if(jobIds.length > 0) {
                    if(window.lbdTrack){
                        lbdTrack.track('JobBindDeptDialogJobList', {
                            dialogId: this.dialogId,
                            jobIds: jobIds
                        });
                    } else {
                        _tracker.track('JobBindDeptDialogJobList', JSON.stringify({
                            dialogId: this.dialogId,
                            jobIds: jobIds
                        }));
                    }
                }
                this.jobData = res.data;
                this.total = res.data.length;
                this.getCustomerNameFilter();
                this.getJobFilterList();
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
            
        },
        getCustomerNameFilter() {
            let customerNameFilter = {}, list = [];
            this.jobData.forEach(item => {
                if(customerNameFilter[item.customerName]) {
                    customerNameFilter[item.customerName].count++;
                } else {
                    customerNameFilter[item.customerName] = {
                        label: item.customerName,
                        isSelect: this.customerFilter.indexOf(item.customerName) > -1 ? true : false,
                        count: 1
                    };
                }
            });
            Object.keys(customerNameFilter).forEach(item => {
                list.push(customerNameFilter[item]);
            })
            list.sort((item1, item2) =>{
                return (item2.count - item1.count);
            });
            this.customerNameSearchGroupedItems = list;
        },
        getJobFilterList() {
            if(this.customerFilter.length > 0) {
                let list = [],
                    jobData = this.jobData,
                    filters = this.customerFilter;
                this.jobData.forEach(item => {
                    if(filters.indexOf(item.customerName) > -1) {
                        list.push(item);
                    }
                })
                this.jobFilterList = list;
            } else {
                this.jobFilterList = this.jobData;
            }
            this.getJobList();
        },
        getJobList() {
            this.jobList = this.jobFilterList;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleAllSelect() {
            this.$refs.jobBindDeptTable.toggleAllSelection();
        },
        multipleJobBind() {
            this.bindJobInfo = {};
            this.bindJobList = this.multipleSelection;
            this.bindCustomerId = this.bindJobList[0].customerId;
            this.$refs.bindDepartment && this.$refs.bindDepartment.reset();
            this.handleBindDepartment();

            let jobIds = [];
            this.bindJobList.forEach(item => {
                jobIds.push(item.jobId);
            })
            if(window.lbdTrack){
                lbdTrack.track('JobBindDeptDialogOperation', {
                    dialogId: this.dialogId,
                    jobIds: jobIds,
                    operationText: '批量绑定职位'
                });
            } else {
                _tracker.track('JobBindDeptDialogOperation', JSON.stringify({
                    dialogId: this.dialogId,
                    jobIds: jobIds,
                    operationText: '批量绑定职位'
                }));
            }
        },
        selectDataChange(label, result) {
            let list = [];
            result.forEach(item => {
                list.push(item.label);
            });
            this.customerFilter = list;
            this.getJobFilterList();
        },
        handleShowTooltip(event){
            let cell = event.target;
            setTimeout(() => {
                this.tooltipDisabled = cell.clientWidth == cell.scrollWidth;
            }, 300);
        },
        handleJumpDetail(item, type) {
            switch(type) {
                case 'job':
                    // window.open(`/Headhunting/MyCompany.html#/Job/${item.jobId}`, '_blank');
                    window.open(`/#/Job/${item.jobId}`, '_blank');
                    break;
                case 'customer':
                    // window.open(`/Headhunting/MyCompany.html#/Customer/${item.customerId}`, '_blank');
                    window.open(`/#/Customer/${item.customerId}`, '_blank');
                    break;
            }
        },
        handleJobStatus(operation, index) {
            this.bindJobInfo = this.jobList[index];
            this.bindJobList = [this.bindJobInfo];
            this.bindCustomerId = this.jobList[index].customerId;
            this.$refs.bindDepartment && this.$refs.bindDepartment.reset();
            this.handleBindDepartment();

            if(window.lbdTrack){
                lbdTrack.track('JobBindDeptDialogOperation', {
                    dialogId: this.dialogId,
                    jobId: this.jobList[index].jobId,
                    operationText: '单个绑定职位'
                });
            } else {
                _tracker.track('JobBindDeptDialogOperation', JSON.stringify({
                    dialogId: this.dialogId,
                    jobId: this.jobList[index].jobId,
                    operationText: '单个绑定职位'
                }));
            }
        },
        hideDialog() {
            this.dialogVisible = false;
            if(document.querySelector('input[name="isCFUser"]')) {
                document.querySelector('.job-bind-dept-wrapper').style.display = "none";
                document.querySelector('.v-modal').parentElement.removeChild(document.querySelector('.v-modal'));
            }
            this.createdNewToOld = false;
            this.updatedNewToOld = true;
            this.customerFilter = [];
        },
        sortTime(code) {
            switch(code) {
                case 0:
                    this.createdNewToOld = !this.createdNewToOld;
                    this.jobFilterList.sort((item1, item2) => {
                        let created1 = new Date(moment(item1.releaseTime).format('YYYY/MM/DD')).getTime(),
                            created2 = new Date(moment(item2.releaseTime).format('YYYY/MM/DD')).getTime();
                        if(this.createdNewToOld) {
                            return Number(created2) - Number(created1);
                        } else {
                            return Number(created1) - Number(created2);
                        }
                    });
                    break;
                case 1:
                    this.updatedNewToOld = !this.updatedNewToOld;
                    this.jobFilterList.sort((item1, item2) => {
                        let updated1 = new Date(moment(item1.updated).format('YYYY/MM/DD')).getTime(),
                            updated2 = new Date(moment(item2.updated).format('YYYY/MM/DD')).getTime();
                        if(this.updatedNewToOld) {
                            return Number(updated2) - Number(updated1);
                        } else {
                            return Number(updated1) - Number(updated2);
                        }
                    });
                    break;
            }
            this.getJobList();
        },
        // checkDoneJob() {
        //     let thisPageJobDone = false;
        //     thisPageJobDone = this.jobList.every(item => {
        //         return item.jobStatus > 0;
        //     })
        //     if(thisPageJobDone) {
        //         if(this.doneNum == this.jobData.length) {
        //             shortTips('真棒，所有职位已经处理完毕');
        //         }
        //     }
        // },
        handleBindDepartment() {
            this.$refs.bindDepartment.show();
        },
        doBind(jobList, departmentId) {
            let jobIds = [];
            this.jobTableLoading = true;
            jobList.forEach(item => {
                jobIds.push(item.jobId);
            })
            JobService.batchBindJobDept({
                jobIds: jobIds,
                departmentId: departmentId
            }).then(res => {
                this.doneNum += jobList.length;
                this.updateList('jobData', jobList);
                this.getCustomerNameFilter();
                this.updateList('jobList', jobList);
                this.updateList('jobFilterList', jobList);
                this.multipleSelection = [];

                if(this.customerFilter.length > 0 && this.jobList.length == 0 && this.jobFilterList.length == 0) {
                    this.$refs.selectPopover && this.$refs.selectPopover.resetSelect();
                    this.jobList = this.jobFilterList = this.jobData;
                }

                this.$emit('unbindDeptJobUpdate', this.jobData.length);
            }).finally(() => {
                this.jobTableLoading = false;
            })
        },
        updateList(listName, jobList) {
            let result = [];
            let listSet = new Set(this[listName]);
            let jobListSet = new Set(jobList);
            this[listName] = this[listName].filter(item => listSet.has(item) && !jobListSet.has(item));
        }
    }
}
</script>

<style lang="scss" scope>
.job-bind-dept {
    position: relative;
    .el-dialog__header {
        font-size: 18px;
        color: #444;
        .title-tip {
            font-size: 14px;
            color: #FF6564;
        }
    }
    .text-primary {
        color: $primary;
    }
    .job-bind {
        &-title {
            text-align: center;
            color: #444;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 18px;   
        }
        &-tip {
            color: #666;
            line-height: 14px;
            margin-bottom: 10px;
        }
        .job-link {
            color: $primary;
            cursor: pointer;
        }
        .customer-detail-link {
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }
        .el-table {
            min-height: 200px;
            max-height: 360px;
            overflow-y: auto;
            .el-loading-mask {
                z-index: 900;
            }
            th {
                padding: 0;
                height: 40px;
                line-height: 40px;
                color: #666666;
                border: none;
                .el-checkbox {
                    padding: 0 10px;
                }
                .cell {
                    padding: 0;
                    .el-checkbox {
                        display: none;
                    }
                }
            }
            td {
                padding: 0;
                height: 40px;
                line-height: 40px;
                border: none;
                .cell {
                    white-space: nowrap;
                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #fff;
                        border-color: $primary;
                        &::after {
                            border-color: $primary;
                        }
                    }
                }

                label.el-checkbox {
                    margin-bottom: 0;
                }
            }
            .el-table-column--selection .cell {
                padding-left: 10px;
                padding-right: 0;
            }
            .el-table__row {
                td {
                    border: none;
                }
                .el-checkbox__inner {
                    border-color: #999;
                }
            }
            .table-slot {
                padding: 0;
                text-indent: 10px;
                line-height: unset;
                .sort-icon {
                    font-size: 14px;
                    color: #666;
                    margin-left: -10px;
                }
                &.time-sort {
                    cursor: pointer;
                }
            }
            .is-center .table-slot {
                text-indent: 0;
            }
            .el-table__empty-block {
                .el-table__empty-text {
                    line-height: inherit;
                }
                .empty-img {
                    display: inline-block;
                    background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                    width: 240px;
                    height: 228px;
                }
                .empty-data {
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 0px;
                }
            }
            .el-table-column--selection {
                border-right: none;
            }
        }
        .job-bind-done {
            color: #666;
            min-height: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .work-table-footer {
            overflow: hidden;
            margin: 20px 0;
            .footer-left {
                float: left;
                height: 28px;
                line-height: 28px;
                letter-spacing: 0px;
                .footer-select {
                    display: inline-block;
                    margin-left: 10px;
                    margin-right: 20px;
                    .all-select-checkbox {
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        margin-right: 6px;
                        width: 16px;
                        height: 16px;
                        border: 1px solid #999;
                        border-radius: 2px;
                        cursor: pointer;
                        background-color: #fff;
                    }
                    input:checked + .all-select-checkbox {
                        border-color: $primary;
                        &:after {
                            position: relative;
                            display: block;
                            content: '';
                            top: 2px;
                            left: 2px;
                            width: 10px;
                            height: 6px;
                            transform: rotate(-45deg);
                            border-left: 1px solid;
                            border-bottom: 1px solid;
                            border-color: $primary;
                        }
                    }
                    .select-count {
                        margin-left: 20px;
                    }
                }
                .batch-operation {
                    display: inline-block;
                    &-btn {
                        height: 28px;
                        min-width: 84px;
                        padding: 0;
                        border-radius: 4px;
                        line-height: 28px;
                        color: #fff;
                        &:hover, &:focus {
                            color: #fff;
                        }
                    }
                    .job-recruiting {
                        background-color: $primary;
                    }
                    .job-paused {
                        background-color: #F5A623;
                        margin-left: 10px;
                    }
                    .job-finished {
                        background-color: #3F85D8;
                    }
                    &-btn.is-disabled, &-btn.is-disabled:hover, &-btn.is-disabled:focus {
                        background: #F8F8F8;
                        border: 1px solid #DDDDDD;
                        color: #999999;
                    }
                }
            }
        }
    }
    .dialog-close {
        width: 18px;
        height: 18px;
        color: #666;
        border-radius: 9px;
        border: 1px solid #666;
        position: absolute;
        right: 14px;
        top: 14px;
        line-height: 18px;
        cursor: pointer;
        &::before {
            content: "×";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .close-tip {
            width: 195px;
            color: #fff;
            visibility: hidden;
            position: absolute;
            left: 40px;
            top: -8px;
        }
        &:hover {
            color: #fff;
            background-color: #e95352;
            border-color: #e95352;
            .close-tip {
                visibility: visible;
            }
        }
    }
    .bubble-tooltip{
        color: #666;
    }
}
</style>

<style lang="scss">
.job-bind-dept {
    .el-table {
        .bubble {
            border: 1px solid #ddd;
            background-color: #fff;
            padding: 0 8px;
            line-height: 26px;
            &.bubble__primary {
                &:hover {
                    background-color: $primary;
                }
            }
            &.bubble__orange {
                &:hover {
                    background-color: $orange;
                }
            }
            &.bubble_blue {
                &.hover {
                    background-color: $blue;
                }
            }
        }
    }
}
</style>