import {jobCategory as jobCategoryUrl} from '#/js/config/api.json';
import {
    jobCategoryData as javaJobCategoryDataUrl,
    jobCategories,
} from '#/js/config/javaApi.json';

export default {
    getCategoryList(params) {
        return _request({
            url: jobCategoryUrl.job_category_tree,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi"
        })
    },
    addJobCategory(params) {
        return _request({
            url: jobCategoryUrl.job_category_add,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        })
    },
    deleteJobCategory(params) {
        return _request({
            url: jobCategoryUrl.job_category_delete.replace(/%p/g, params.jobCategoryId),
            method: "DELETE",
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        })
    },
    editJobCategory(params) {
        return _request({
            url: jobCategoryUrl.job_category_edit,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        })
    },
    mergeJobCategory(params) {
        return _request({
            url: jobCategoryUrl.job_category_merge,
            method: "POST",
            data: params,
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        })
    },
    getJobCategoryFilledStatus() {
        return _request({
            url: jobCategoryUrl.get_filled_job_category,
            method: "GET",
            baseURL: "LbdOpenApi"
        })
    },
    setJobCategoryFilledStatus(params) {
        return _request({
            url: jobCategoryUrl.set_filled_job_category.replace(/%p/g, params.mustFilledIn),
            method: "POST",
            baseURL: "LbdOpenApi",
            throwBusinessError: true
        })
    },
    checkIfNeedShowDialog() {
        return _request({
            method: 'GET',
            url: jobCategoryUrl.check_if_need_show_dialog,
            baseURL: "LbdOpenApi"
        })
    },
    deleteExpiredJobCategoryMapping() {
        return _request({
            method: 'DELETE',
            url: jobCategoryUrl.delete_expired_job_category_mapping,
            baseURL: "LbdOpenApi"
        })
    },
    getJavaJobCategories() {
        return _request({
            method: 'GET',
            url: javaJobCategoryDataUrl.job_categories,
            baseURL: 'LbdJavaApi',
            javaProject: 'performance'
        })
    },
    getTrackReportList(params) {
        return _request({
            method: 'GET',
            url: javaJobCategoryDataUrl.track_report_list,
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    exportTrackReportList(params) {
        return _request({
            method: 'GET',
            url: javaJobCategoryDataUrl.track_report_export,
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            responseType: 'blob',
            data: params
        })
    },
    getMemberReportList(params) {
        return _request({
            method: 'POST',
            url: javaJobCategoryDataUrl.member_report_list,
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            data: params
        })
    },
    exportMemberReportList(params) {
        return _request({
            method: 'POST',
            url: javaJobCategoryDataUrl.member_report_export,
            baseURL: 'LbdJavaApi',
            javaProject: 'performance',
            responseType: 'blob',
            data: params
        })
    },
    getCategoriesByImNotifycation(date) {
        return _request({
            method: 'GET',
            url: jobCategoryUrl.get_categories_from_im.replace(/%p/g, date),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },
    getCategoryJobList(params) {
        return _request({
            method: 'POST',
            url: jobCategoryUrl.get_category_job_list,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: params
        })
    },

    // 职位分类树
    jobCategoriesTree(params) {
        return _request({
            method: 'POST',
            url: jobCategories.job_categories_tree,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    // 启用
    jobCategoriesEnable(params) {
        return _request({
            method: 'POST',
            url: jobCategories.job_categories_enable,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    // 添加职位类别
    jobCategoriesAdd(params) {
        return _request({
            method: 'POST',
            url: jobCategories.job_categories_add,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    // 编辑职位类别
    jobCategoriesEdit(params) {
        return _request({
            method: 'POST',
            url: jobCategories.job_categories_edit,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    // 获取公司成员做单方向设置
    jobCategoriesGetOption(params) {
        return _request({
            method: 'GET',
            url: jobCategories.job_categories_get_option,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    // 修改公司成员做单方向设置
    jobCategoriesEditOption(params) {
        return _request({
            method: 'POST',
            url: jobCategories.job_categories_edit_option.replace(/\%p/ig, params.isMustFill),
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            // data: params,
        });
    },

    // 合并职位类别
    jobCategoriesMerge(params) {
        return _request({
            method: 'POST',
            url: jobCategories.job_categories_merge,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
        });
    },

    // 拖动职位类别
    jobCategoriesMove(params) {
        return _request({
            method: 'POST',
            url: jobCategories.job_categories_move,
            baseURL: 'LbdJavaApi',
            javaProject: 'javaSetting',
            data: params,
            throwBusinessError: true,
        });
    },
}