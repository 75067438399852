<template>
  <el-dialog
    :visible="dialogVisible"
    width="890px"
    :show-close="false"
    v-loading="loading"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="handleClose"
    custom-class="standstill-job-dialog"
    class="standstill-job-dialog-wrapper"
    :append-to-body="true"
  >
    <span slot="title">
        更新僵尸职位状态<span class="title-tip" v-if="isCfUser">（请及时处理，否则将不能创建新职位）</span>
    </span>
    <div class="standstill-job">
        <div class="standstill-job-title">
            你有{{jobData.length}}个招聘中职位已经一个月没有更新！
        </div>
        <div class="standstill-job-tip">
            请确认以下职位是否还在招聘中，已处理<span class="text-primary">{{doneNum}}/{{jobData.length}}</span>。
        </div>
        <el-table
            style="width:100%"
            ref="standstillJobTable"
            :data="jobList"
            v-loading="jobTableLoading"
            @selection-change="handleSelectionChange"
            v-if="doneNum < jobData.length">
            <el-table-column
                type="selection"
                width="28"
            ></el-table-column>
            <el-table-column
                prop="jobName"
                label="职位名称"
                width="170"
                :resizable="false"
                :show-overflow-tooltip="true"
            >
                <template slot="header">
                    <div class="table-slot">
                        职位名称
                    </div>
                </template>
                <template slot-scope="scope">
                    <el-tooltip
                        effect="light"
                        :content="scope.row.jobName"
                        placement="top"
                        :open-delay="300"
                        :disabled="tooltipDisabled"
                        @mouseenter.native ="handleShowTooltip($event)"
                    >
                        <span
                            class="job-link"
                            @click.prevent="handleJumpDetail(scope.row, 'job')"
                        >
                            {{scope.row.jobName}}
                        </span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                label="客户名称"
                prop="customerName"
                width="116"
                header-align="left"
                :show-overflow-tooltip="true"
            >
                <template slot="header" slot-scope="scope">
                    <select-popover
                        :enableSearch="true"
                        :enableCount="true"
                        :selectItem="customerNameSearchGroupedItems"
                        :label="'customerNames'"
                        :title="'客户名称'"
                        :maxSelect="5"
                        @selectChange="selectDataChange"
                    ></select-popover>
                </template>
                <template slot-scope="scope">
                    <el-tooltip
                        effect="light"
                        :content="scope.row.customerName"
                        placement="top"
                        :open-delay="300"
                        :disabled="tooltipDisabled"
                        @mouseenter.native ="handleShowTooltip($event)"
                    >
                        <!-- <span
                            class="customer-detail-link"
                            @click.prevent="handleJumpDetail(scope.row, 'customer')"
                        >
                            {{scope.row.customerName}}
                        </span> -->
                        <span>{{scope.row.customerName}}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                width="240"
            >
                <template slot="header">
                    <div class="table-slot">
                        操作（请处理）
                    </div>
                </template>
                <template slot-scope="scope">
                    <bubble
                        v-for="(item, index) in operations"
                        :key="index"
                        :enableCount="false"
                        :enableTooltip="item.enableTooltip && !scope.row.jobStatus"
                        :isSelect="item.status == scope.row.jobStatus"
                        group="Label"
                        :type="item.color"
                        @click="handleJobStatus(item, scope.$index)"
                    >
                        {{ item.text }}
                        <span v-if="item.enableTooltip && !scope.row.jobStatus" slot="tooltipContent">
                            {{ item.toolTip}}
                        </span>
                    </bubble>
                </template>
            </el-table-column>
            <el-table-column
                width="140"
                prop="created"
            >
                <template slot="header">
                    <div class="table-slot time-sort" @click="sortTime(0)">
                        发布时间
                        <i class="el-icon-sort sort-icon"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <span>
                        {{ scope.row.created | formatDate }} 发布
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                width="140"
                prop="updated"
            >
                <template slot="header">
                    <div class="table-slot time-sort" @click="sortTime(1)">
                        最近更新时间
                        <i class="el-icon-sort sort-icon"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <span>
                        {{ scope.row.updated | formatDate }} 更新
                    </span>
                </template>
            </el-table-column>
            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </template>
        </el-table>
        <div class="standstill-job-done" v-if="doneNum === jobData.length">
            <p>真棒，所有职位已经处理完毕。</p>
            <p>更新其他招聘中职位，请<a href="/Headhunting/#/myOrders/createdJob?status=1" target="_blank" class="text-operate-btn">点击这里</a></p>
        </div>
        <div class="work-table-footer">
            <div class="footer-left">
                <div class="footer-select">
                    <input hidden v-model="allSelect" type="checkbox">
                    <span class="all-select-checkbox" @click="handleAllSelect"></span>
                    <span>全选</span>
                    <span class="select-count">已选择
                        <span class="color-orange">{{multipleCount}}</span>
                        个职位
                    </span>
                </div>
                <div class="batch-operation">
                    <el-popover
                        placement="bottom-start"
                        popper-class="popper-bubble"
                        width="auto"
                        :disabled="multipleCount == 0"
                        :visible-arrow="false"
                        trigger="hover"
                    >
                        <el-button
                            slot="reference"
                            class="batch-operation-btn job-recruiting"
                            :disabled="multipleCount == 0"
                            @click="multipleJobStatus(1, 'batchRecruitingJob', '不变')"
                        >
                            不变
                        </el-button>
                        <span class="bubble-tooltip">
                            选中后，将更新该职位的更新时间
                        </span>
                    </el-popover>
                    <el-button
                        class="batch-operation-btn job-paused"
                        :disabled="multipleCount == 0"
                        @click="multipleJobStatus(2, 'batchPausedJob', '职位暂停')"
                    >
                        职位暂停
                    </el-button>
                    <el-button
                        class="batch-operation-btn job-finished"
                        :disabled="multipleCount == 0"
                        @click="multipleJobStatus(4, 'batchFinishedJob', '职位完成')"
                    >
                        职位完成
                    </el-button>
                </div>
            </div>
            <el-pagination
                :current-page="pager.current"
                :page-size="pager.size"
                class="el-pagination-workTable"
                layout="prev, pager, next"
                :total="pager.total"
                @current-change="handleCurrentChange"
            ></el-pagination>
        </div>
    </div>
    <div class="dialog-close" @click="hideDialog">
        <span class="close-tip">
            <template v-if="isCfUser && jobData.length !== doneNum">
                请及时处理，否则将不能创建新职位。<br/>
            </template>
            关闭后，可在页面右侧再次打开。
        </span>
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment';
import Bubble from '#/component/common/bubble.vue';
import SelectPopover from '#/component/common/select-popover.vue';
import JobService from '#/js/service/jobService.js';
import myOrdersService from "@src/js/service/myOrdersService.js";
export default {
    components: {
        Bubble,
        SelectPopover
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            jobList: [],
            jobTableLoading: false,
            multipleSelection: [],
            customerNameSearchGroupedItems: [],
            pager: {
                current: 1,
                size: 8,
                total: 0
            },
            tooltipDisabled: true,
            operations: [{
                enableTooltip: true,
                status: 1,
                color: 'primary',
                text: '不变',
                type: "recruitingJob",
                toolTip: '选中后，将更新该职位的更新时间'
            }, {
                enableTooltip: false,
                status: 2,
                color: 'orange',
                text: '暂停',
                type: 'pauseJob',
                toolTip: ''
            }, {
                enableTooltip: false,
                status: 4,
                color: 'blue',
                text: '完成',
                type: 'finishJob',
                toolTip: ''
            }],
            createdNewToOld: false,
            updatedNewToOld: true, 
            jobData: [],
            jobFilterList: [],
            customerFilter: [],
            dialogId: "",
            isFromClick: false
        }
    },
    computed: {
        doneNum() {
            let num = 0;
            this.jobData.forEach(item => {
                if(item.jobStatus > 0) {
                    num++;
                }
            });
            return num;
        },
        isCfUser() {
            return this.$store.state.user.userInfo.isCFUser ? this.$store.state.user.userInfo.isCFUser : $('input[name="isCFUser"]').val();
        },
        multipleCount() {
            return this.multipleSelection.length;
        },
        allSelect() {
            return this.jobList.length > 0 ? this.jobList.length == this.multipleSelection.length : false;
        },
    },
    filters: {
        formatDate(string) {
            return moment(string).format('YYYY.MM.DD');
        }
    },
    methods: {
        show(dialogId, isFromClick) {
            this.dialogVisible = true;
            this.dialogId = dialogId;
            this.isFromClick = isFromClick;
            this.getJobData();
        },
        getJobData() {
            this.loading = true;
            this.jobData = [];
            JobService.getStandStillJob({includeDetail: true}).then(res => {
                let jobIds = [];
                res.ossifiedJobs.forEach(item => {
                    item.jobStatus = 0;
                    jobIds.push(item.jobId);
                });
                res.ossifiedJobs.sort((item1, item2) => {
                    return new Date(item2.updated).getTime() - new Date(item1.updated).getTime()
                })
                if(jobIds.length > 0) {
                    if(window.lbdTrack){
                        lbdTrack.track('StandstillJobDialogJobList', {
                            dialogId: this.dialogId,
                            jobIds: jobIds
                        });
                    } else {
                        _tracker.track('StandstillJobDialogJobList', JSON.stringify({
                            dialogId: this.dialogId,
                            jobIds: jobIds
                        }));
                    }
                }
                this.jobData = res.ossifiedJobs;
                this.getCustomerNameFilter();
                this.getJobFilterList();
            }).catch(err => {
                console.log(err);
            });
            this.loading = false;
        },
        getCustomerNameFilter() {
            let customerNameFilter = {}, list = [];
            this.jobData.forEach(item => {
                if(customerNameFilter[item.customerName]) {
                    customerNameFilter[item.customerName].count++;
                } else {
                    customerNameFilter[item.customerName] = {
                        label: item.customerName,
                        isSelect: this.customerFilter.indexOf(item.customerName) > -1 ? true : false,
                        count: 1
                    };
                }
            });
            Object.keys(customerNameFilter).forEach(item => {
                list.push(customerNameFilter[item]);
            })
            list.sort((item1, item2) =>{
                return (item2.count - item1.count);
            });
            this.customerNameSearchGroupedItems = list;
        },
        getJobFilterList() {
            if(this.customerFilter.length > 0) {
                let list = [],
                    jobData = this.jobData,
                    filters = this.customerFilter;
                this.jobData.forEach(item => {
                    if(filters.indexOf(item.customerName) > -1) {
                        list.push(item);
                    }
                })
                this.jobFilterList = list;
            } else {
                this.jobFilterList = this.jobData;
            }
            this.pager.total = this.jobFilterList.length;
            this.pager.current = 1;
            this.getJobList();
        },
        getJobList() {
            let start = 0;
            start = (this.pager.current - 1) * this.pager.size;
            this.jobList = this.jobFilterList.slice(start, start + this.pager.size);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleAllSelect() {
            this.$refs.standstillJobTable.toggleAllSelection();
        },
        multipleJobStatus(status, btnType, btnText) {
            let tipText = "";
            switch(status) {
                // case 1: tipText = `确定批量将这${this.multipleSelection.length}个职位设为继续招聘吗？`;break;
                case 1: tipText = `确定批量设置保持这${this.multipleSelection.length}个职位的状态不变吗？`;break;
                case 2: tipText = `确定批量暂停这${this.multipleSelection.length}个职位吗？`;break;
                case 4: tipText = `确定批量完成这${this.multipleSelection.length}个职位吗？`;break;
            }
            this.$confirm(tipText, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
            }).then(() => {
                let dataJson = [], jobIds = [];
                this.multipleSelection.forEach(item => {
                    let itemObj = {
                        jobId: item.jobId,
                        jobStatus: status,
                        isUpdateTime: status == 1 ? true : false
                    };
                    dataJson.push(itemObj);
                    jobIds.push(item.jobId);
                });
                if(window.lbdTrack){
                    lbdTrack.track('StandstillJobDialogOperation', {
                        dialogId: this.dialogId,
                        jobIds: jobIds,
                        operationType: btnType,
                        operationText: `批量操作${btnText}`
                    });
                } else {
                    _tracker.track('StandstillJobDialogOperation', JSON.stringify({
                        dialogId: this.dialogId,
                        jobIds: jobIds,
                        operationType: btnType,
                        operationText: `批量操作${btnText}`
                    }));
                }
                this.jobTableLoading = true;
                myOrdersService.updateJobStatus(dataJson)
                .then(res => {
                    this.jobTableLoading = false;
                    shortTips('批量更新职位状态成功');
                    this.multipleSelection.forEach(item => {
                        item.jobStatus = status;
                        item.updated = new Date().toISOString();
                    });
                    this.$refs.standstillJobTable.clearSelection();
                    this.$emit('standstillJobUpdate', (this.jobData.length - this.doneNum));
                    this.checkDoneJob();
                })
                .catch(err => {
                    console.log(err);
                    this.jobTableLoading = false;
                });
            }).catch(() => {});
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.getJobList();
        },
        selectDataChange(label, result) {
            let list = [];
            result.forEach(item => {
                list.push(item.label);
            });
            this.customerFilter = list;
            this.getJobFilterList();
        },
        handleShowTooltip(event){
            let cell = event.target;
            setTimeout(() => {
                this.tooltipDisabled = cell.clientWidth == cell.scrollWidth;
            }, 300);
        },
        handleJumpDetail(item, type) {
            switch(type) {
                case 'job':
                    // window.open(`/Headhunting/MyCompany.html#/Job/${item.jobId}`, '_blank');
                    window.open(`/#/Job/${item.jobId}`, '_blank');
                    break;
                case 'customer':
                    // window.open(`/Headhunting/MyCompany.html#/Customer/${item.customerId}`, '_blank');
                    window.open(`/#/Customer/${item.customerId}`, '_blank');
                    break;
            }
        },
        handleJobStatus(operation, index) {
            let dataJson = [];
            dataJson.push({
                jobId: this.jobList[index].jobId,
                jobStatus: operation.status,
                isUpdateTime: operation.status == 1 ? true : false
            });
            if(window.lbdTrack){
                lbdTrack.track('StandstillJobDialogOperation', {
                    dialogId: this.dialogId,
                    jobId: this.jobList[index].jobId,
                    operationType: operation.type,
                    operationText: operation.text
                });
            } else {
                _tracker.track('StandstillJobDialogOperation', JSON.stringify({
                    dialogId: this.dialogId,
                    jobId: this.jobList[index].jobId,
                    operationType: operation.type,
                    operationText: operation.text
                }));
            }
            this.jobTableLoading = true;
            myOrdersService.updateJobStatus(dataJson)
            .then(res => {
                this.jobTableLoading = false;
                shortTips('职位状态更新成功');
                this.jobList[index].jobStatus = operation.status;
                this.jobList[index].updated = new Date().toISOString();
                this.$emit('standstillJobUpdate', (this.jobData.length - this.doneNum));
                if(index == this.jobList.length - 1) {
                    setTimeout(() => {
                        this.checkDoneJob();
                    }, 1000);
                } else {
                    this.checkDoneJob();
                }

            })
            .catch(err => {
                console.log(err);
                this.jobTableLoading = false;
            });
        },
        hideDialog() {
            this.dialogVisible = false;
            if(document.querySelector('input[name="isCFUser"]')) {
                document.querySelector('.standstill-job-dialog-wrapper').style.display = "none";
                document.querySelector('.v-modal').parentElement.removeChild(document.querySelector('.v-modal'));
            }
            this.createdNewToOld = false;
            this.updatedNewToOld = true;
            this.customerFilter = [];
        },
        sortTime(code) {
            switch(code) {
                case 0:
                    this.createdNewToOld = !this.createdNewToOld;
                    this.jobFilterList.sort((item1, item2) => {
                        let created1 = new Date(moment(item1.created).format('YYYY/MM/DD')).getTime(),
                            created2 = new Date(moment(item2.created).format('YYYY/MM/DD')).getTime();
                        if(this.createdNewToOld) {
                            return Number(created2) - Number(created1);
                        } else {
                            return Number(created1) - Number(created2);
                        }
                    });
                    break;
                case 1:
                    this.updatedNewToOld = !this.updatedNewToOld;
                    this.jobFilterList.sort((item1, item2) => {
                        let updated1 = new Date(moment(item1.updated).format('YYYY/MM/DD')).getTime(),
                            updated2 = new Date(moment(item2.updated).format('YYYY/MM/DD')).getTime();
                        if(this.updatedNewToOld) {
                            return Number(updated2) - Number(updated1);
                        } else {
                            return Number(updated1) - Number(updated2);
                        }
                    });
                    break;
            }
            this.pager.current = 1;
            this.getJobList();
        },
        checkDoneJob() {
            let thisPageJobDone = false;
            thisPageJobDone = this.jobList.every(item => {
                return item.jobStatus > 0;
            })
            if(thisPageJobDone) {
                if(this.doneNum == this.jobData.length) {
                    shortTips('真棒，所有职位已经处理完毕');
                } else if(this.pager.current != Math.ceil(this.pager.total / this.pager.size)){
                    shortTips('正在跳转到下一页...');
                    setTimeout(() => {
                        this.pager.current++;
                        this.getJobList();
                    }, 1000);
                }
            }
        },
        handleClose() {
            if(!this.isFromClick) {
                this.$emit('auto-show-dialog-close');
            }
        }
    }
}
</script>

<style lang="scss" scope>
.standstill-job-dialog {
    position: relative;
    .el-dialog__header {
        font-size: 18px;
        color: #444;
        .title-tip {
            font-size: 14px;
            color: #FF6564;
        }
    }
    .text-primary {
        color: $primary;
    }
    .standstill-job {
        &-title {
            text-align: center;
            color: #444;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 18px;   
        }
        &-tip {
            color: #666;
            line-height: 14px;
            margin-bottom: 10px;
        }
        .job-link {
            color: $primary;
            cursor: pointer;
        }
        .customer-detail-link {
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }
        .el-table {
            min-height: 200px;
            max-height: 360px;
            overflow-y: auto;
            .el-loading-mask {
                z-index: 900;
            }
            th {
                padding: 0;
                height: 40px;
                line-height: 40px;
                color: #666666;
                border: none;
                .el-checkbox {
                    padding: 0 10px;
                }
                .cell {
                    padding: 0;
                    .el-checkbox {
                        display: none;
                    }
                }
            }
            td {
                padding: 0;
                height: 40px;
                line-height: 40px;
                border: none;
                .cell {
                    white-space: nowrap;
                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #fff;
                        border-color: $primary;
                        &::after {
                            border-color: $primary;
                        }
                    }
                }

                label.el-checkbox {
                    margin-bottom: 0;
                }
            }
            .el-table-column--selection .cell {
                padding-left: 10px;
                padding-right: 0;
            }
            .el-table__row {
                td {
                    border: none;
                }
                .el-checkbox__inner {
                    border-color: #999;
                }
            }
            .table-slot {
                padding: 0;
                text-indent: 10px;
                line-height: unset;
                .sort-icon {
                    font-size: 14px;
                    color: #666;
                    margin-left: -10px;
                }
                &.time-sort {
                    cursor: pointer;
                }
            }
            .is-center .table-slot {
                text-indent: 0;
            }
            .el-table__empty-block {
                .el-table__empty-text {
                    line-height: inherit;
                }
                .empty-img {
                    display: inline-block;
                    background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                    width: 240px;
                    height: 228px;
                }
                .empty-data {
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 0px;
                }
            }
            .el-table-column--selection {
                border-right: none;
            }
        }
        .standstill-job-done {
            color: #666;
            min-height: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .work-table-footer {
            overflow: hidden;
            margin: 20px 0;
            .footer-left {
                float: left;
                height: 28px;
                line-height: 28px;
                letter-spacing: 0px;
                .footer-select {
                    display: inline-block;
                    margin-left: 10px;
                    margin-right: 20px;
                    .all-select-checkbox {
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        margin-right: 6px;
                        width: 16px;
                        height: 16px;
                        border: 1px solid #999;
                        border-radius: 2px;
                        cursor: pointer;
                        background-color: #fff;
                    }
                    input:checked + .all-select-checkbox {
                        border-color: $primary;
                        &:after {
                            position: relative;
                            display: block;
                            content: '';
                            top: 2px;
                            left: 2px;
                            width: 10px;
                            height: 6px;
                            transform: rotate(-45deg);
                            border-left: 1px solid;
                            border-bottom: 1px solid;
                            border-color: $primary;
                        }
                    }
                    .select-count {
                        margin-left: 20px;
                    }
                }
                .batch-operation {
                    display: inline-block;
                    &-btn {
                        height: 28px;
                        min-width: 84px;
                        padding: 0;
                        border-radius: 4px;
                        line-height: 28px;
                        color: #fff;
                        &:hover, &:focus {
                            color: #fff;
                        }
                    }
                    .job-recruiting {
                        background-color: $primary;
                    }
                    .job-paused {
                        background-color: #F5A623;
                        margin-left: 10px;
                    }
                    .job-finished {
                        background-color: #3F85D8;
                    }
                    &-btn.is-disabled, &-btn.is-disabled:hover, &-btn.is-disabled:focus {
                        background: #F8F8F8;
                        border: 1px solid #DDDDDD;
                        color: #999999;
                    }
                }
            }
            .el-pagination.el-pagination-workTable {
                float: right;
                padding: 0;
                font-weight: normal;
                .btn-next, .btn-prev {
                    height: 28px;
                    border: 1px solid;
                }
                .btn-prev {
                    border-right: none;
                    border-radius: 4px 0 0 4px;
                }
                .btn-next {
                    border-left: none;
                    border-radius: 0 4px 4px 0;
                }
                .el-pagination__sizes .el-input{
                    width: 87px;
                    .el-input__inner {
                        color: #666;
                        .el-select__caret{
                            color: #999;
                        }
                    }
                }
                .el-pager {
                    border-top: 1px solid;
                    border-bottom: 1px solid;
                    li {
                        min-width: 16.5px;
                        height: 26px;
                        line-height: 26px;
                        font-size: 14px;
                    }
                }
                .btn-next, .btn-prev, .el-pager {
                    border-color: #DDDDDD;
                }
                .el-input__inner, .el-pagination__total, .el-pagination__jump {
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                }
                .pagination-text{
                    color: #999;
                    span{
                        height: 28px;
                        line-height: 28px;
                        font-size: 14px;
                        margin-left: 30px;
                        .el-input{
                            width: 48px;
                            margin: 0 5px;
                            &__inner{
                                height: 28px;
                                line-height: 28px;
                                padding: 0 5px;
                            }
                        }
                        &:nth-of-type(2){
                            margin-left: 5px;
                            color: $primary;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .dialog-close {
        width: 18px;
        height: 18px;
        color: #666;
        border-radius: 9px;
        border: 1px solid #666;
        position: absolute;
        right: 14px;
        top: 14px;
        line-height: 18px;
        cursor: pointer;
        &::before {
            content: "×";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .close-tip {
            width: 195px;
            color: #fff;
            display: none;
            position: absolute;
            left: 40px;
            top: -8px;
        }
        &:hover {
            color: #fff;
            background-color: #e95352;
            border-color: #e95352;
            .close-tip {
                display: inline-block;
            }
        }
    }
    .bubble-tooltip{
        color: #666;
    }
}
</style>

<style lang="scss">
.standstill-job-dialog {
    .el-table {
        .bubble {
            border: 1px solid #ddd;
            background-color: #fff;
            padding: 0 8px;
            line-height: 26px;
            &.bubble__primary {
                &:hover {
                    background-color: $primary;
                }
            }
            &.bubble__orange {
                &:hover {
                    background-color: $orange;
                }
            }
            &.bubble_blue {
                &.hover {
                    background-color: $blue;
                }
            }
        }
    }
}
</style>