var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "standstill-job-dialog-wrapper",
      attrs: {
        visible: _vm.dialogVisible,
        width: "890px",
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "custom-class": "standstill-job-dialog",
        "append-to-body": true,
      },
      on: { closed: _vm.handleClose },
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("\n      更新僵尸职位状态"),
        _vm.isCfUser
          ? _c("span", { staticClass: "title-tip" }, [
              _vm._v("（请及时处理，否则将不能创建新职位）"),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "standstill-job" },
        [
          _c("div", { staticClass: "standstill-job-title" }, [
            _vm._v(
              "\n          你有" +
                _vm._s(_vm.jobData.length) +
                "个招聘中职位已经一个月没有更新！\n      "
            ),
          ]),
          _c("div", { staticClass: "standstill-job-tip" }, [
            _vm._v("\n          请确认以下职位是否还在招聘中，已处理"),
            _c("span", { staticClass: "text-primary" }, [
              _vm._v(_vm._s(_vm.doneNum) + "/" + _vm._s(_vm.jobData.length)),
            ]),
            _vm._v("。\n      "),
          ]),
          _vm.doneNum < _vm.jobData.length
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.jobTableLoading,
                      expression: "jobTableLoading",
                    },
                  ],
                  ref: "standstillJobTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.jobList },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "28" },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "jobName",
                        label: "职位名称",
                        width: "170",
                        resizable: false,
                        "show-overflow-tooltip": true,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "light",
                                      content: scope.row.jobName,
                                      placement: "top",
                                      "open-delay": 300,
                                      disabled: _vm.tooltipDisabled,
                                    },
                                    nativeOn: {
                                      mouseenter: function ($event) {
                                        return _vm.handleShowTooltip($event)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "job-link",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleJumpDetail(
                                              scope.row,
                                              "job"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                          " +
                                            _vm._s(scope.row.jobName) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2588342399
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", { staticClass: "table-slot" }, [
                          _vm._v(
                            "\n                      职位名称\n                  "
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      label: "客户名称",
                      prop: "customerName",
                      width: "116",
                      "header-align": "left",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function (scope) {
                            return [
                              _c("select-popover", {
                                attrs: {
                                  enableSearch: true,
                                  enableCount: true,
                                  selectItem:
                                    _vm.customerNameSearchGroupedItems,
                                  label: "customerNames",
                                  title: "客户名称",
                                  maxSelect: 5,
                                },
                                on: { selectChange: _vm.selectDataChange },
                              }),
                            ]
                          },
                        },
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "light",
                                    content: scope.row.customerName,
                                    placement: "top",
                                    "open-delay": 300,
                                    disabled: _vm.tooltipDisabled,
                                  },
                                  nativeOn: {
                                    mouseenter: function ($event) {
                                      return _vm.handleShowTooltip($event)
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.customerName)),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1746049818
                    ),
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: { label: "操作", width: "240" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return _vm._l(
                                _vm.operations,
                                function (item, index) {
                                  return _c(
                                    "bubble",
                                    {
                                      key: index,
                                      attrs: {
                                        enableCount: false,
                                        enableTooltip:
                                          item.enableTooltip &&
                                          !scope.row.jobStatus,
                                        isSelect:
                                          item.status == scope.row.jobStatus,
                                        group: "Label",
                                        type: item.color,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleJobStatus(
                                            item,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(item.text) +
                                          "\n                      "
                                      ),
                                      item.enableTooltip && !scope.row.jobStatus
                                        ? _c(
                                            "span",
                                            {
                                              attrs: { slot: "tooltipContent" },
                                              slot: "tooltipContent",
                                            },
                                            [
                                              _vm._v(
                                                "\n                          " +
                                                  _vm._s(item.toolTip) +
                                                  "\n                      "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        907366320
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("div", { staticClass: "table-slot" }, [
                          _vm._v(
                            "\n                      操作（请处理）\n                  "
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: { width: "140", prop: "created" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm._f("formatDate")(scope.row.created)
                                      ) +
                                      " 发布\n                  "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1959114234
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          {
                            staticClass: "table-slot time-sort",
                            on: {
                              click: function ($event) {
                                return _vm.sortTime(0)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                      发布时间\n                      "
                            ),
                            _c("i", { staticClass: "el-icon-sort sort-icon" }),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: { width: "140", prop: "updated" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        _vm._f("formatDate")(scope.row.updated)
                                      ) +
                                      " 更新\n                  "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2506247033
                      ),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          {
                            staticClass: "table-slot time-sort",
                            on: {
                              click: function ($event) {
                                return _vm.sortTime(1)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                      最近更新时间\n                      "
                            ),
                            _c("i", { staticClass: "el-icon-sort sort-icon" }),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c("template", { slot: "empty" }, [
                    _c("div", [
                      _c("span", { staticClass: "empty-img" }),
                      _c("p", { staticClass: "empty-data" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm.doneNum === _vm.jobData.length
            ? _c("div", { staticClass: "standstill-job-done" }, [
                _c("p", [_vm._v("真棒，所有职位已经处理完毕。")]),
                _c("p", [
                  _vm._v("更新其他招聘中职位，请"),
                  _c(
                    "a",
                    {
                      staticClass: "text-operate-btn",
                      attrs: {
                        href: "/Headhunting/#/myOrders/createdJob?status=1",
                        target: "_blank",
                      },
                    },
                    [_vm._v("点击这里")]
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "work-table-footer" },
            [
              _c("div", { staticClass: "footer-left" }, [
                _c("div", { staticClass: "footer-select" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.allSelect,
                        expression: "allSelect",
                      },
                    ],
                    attrs: { hidden: "", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.allSelect)
                        ? _vm._i(_vm.allSelect, null) > -1
                        : _vm.allSelect,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.allSelect,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.allSelect = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.allSelect = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.allSelect = $$c
                        }
                      },
                    },
                  }),
                  _c("span", {
                    staticClass: "all-select-checkbox",
                    on: { click: _vm.handleAllSelect },
                  }),
                  _c("span", [_vm._v("全选")]),
                  _c("span", { staticClass: "select-count" }, [
                    _vm._v("已选择\n                      "),
                    _c("span", { staticClass: "color-orange" }, [
                      _vm._v(_vm._s(_vm.multipleCount)),
                    ]),
                    _vm._v(
                      "\n                      个职位\n                  "
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "batch-operation" },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom-start",
                          "popper-class": "popper-bubble",
                          width: "auto",
                          disabled: _vm.multipleCount == 0,
                          "visible-arrow": false,
                          trigger: "hover",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "batch-operation-btn job-recruiting",
                            attrs: {
                              slot: "reference",
                              disabled: _vm.multipleCount == 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.multipleJobStatus(
                                  1,
                                  "batchRecruitingJob",
                                  "不变"
                                )
                              },
                            },
                            slot: "reference",
                          },
                          [
                            _vm._v(
                              "\n                          不变\n                      "
                            ),
                          ]
                        ),
                        _c("span", { staticClass: "bubble-tooltip" }, [
                          _vm._v(
                            "\n                          选中后，将更新该职位的更新时间\n                      "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "batch-operation-btn job-paused",
                        attrs: { disabled: _vm.multipleCount == 0 },
                        on: {
                          click: function ($event) {
                            return _vm.multipleJobStatus(
                              2,
                              "batchPausedJob",
                              "职位暂停"
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                      职位暂停\n                  "
                        ),
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "batch-operation-btn job-finished",
                        attrs: { disabled: _vm.multipleCount == 0 },
                        on: {
                          click: function ($event) {
                            return _vm.multipleJobStatus(
                              4,
                              "batchFinishedJob",
                              "职位完成"
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                      职位完成\n                  "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-pagination", {
                staticClass: "el-pagination-workTable",
                attrs: {
                  "current-page": _vm.pager.current,
                  "page-size": _vm.pager.size,
                  layout: "prev, pager, next",
                  total: _vm.pager.total,
                },
                on: { "current-change": _vm.handleCurrentChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-close", on: { click: _vm.hideDialog } },
        [
          _c(
            "span",
            { staticClass: "close-tip" },
            [
              _vm.isCfUser && _vm.jobData.length !== _vm.doneNum
                ? [
                    _vm._v(
                      "\n              请及时处理，否则将不能创建新职位。"
                    ),
                    _c("br"),
                  ]
                : _vm._e(),
              _vm._v("\n          关闭后，可在页面右侧再次打开。\n      "),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }